/*

READ ME!

!! SERIOUSLY YOU SHOULD README !!

- DO-NOT DELETE ME, CI/CD NEEDS ME.
- PUT YOUR NGROK LINK HERE.
- CI/CD WILL REPLACE THE LINK IF YOU ACCIDENTALY FORGET
- NEVER USE api.navipointhealth.com NEVER!!!
- backend link
 */
const apiLinks = {
  main: process.env.VUE_APP_BASE_URL,
}
export default apiLinks
