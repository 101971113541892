import { Ability } from '@casl/ability'
import { initialAbility } from './config'

export const canNavigate = to => to.matched.some(route => {
  const userData = JSON.parse(localStorage.getItem('adminData'))
  const existingAbility = userData ? userData.ability : null

  const ability = new Ability(existingAbility || initialAbility)
  return ability.can(route.meta.action || 'read', route.meta.resource)
})
export const _ = undefined
