import Vue from 'vue'

// axios
import axios from 'axios'
import axiosConfigs from '../axios.config'

const baseURL = axiosConfigs.main

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL,
})

axiosIns.interceptors.request.use(request => {
  const authToken = localStorage.getItem('adminToken')
  // eslint-disable-next-line no-param-reassign
  if (authToken !== null) request.headers.Token = authToken
  return request
})

Vue.prototype.$http = axiosIns

export default axiosIns
