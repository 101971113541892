import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'

import axios from '@/libs/axios'

// Color picker
import { ColorPicker, ColorPanel } from 'one-colorpicker'

// Vuejs Logger for better logging
import VueLogger from 'vuejs-logger'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import 'vue-select/dist/vue-select.css'

const isProduction = process.env.NODE_ENV === 'production'
const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
}

// Color picker
Vue.use(ColorPanel)
Vue.use(ColorPicker)

Vue.use(VueLogger, options)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// Custom Mixins
Vue.mixin({
  methods: {
    // =============================================================================
    // Open global overLay/loading
    // =============================================================================
    showLoading(text, type = 'border', variant = 'primary') {
      this.$store.commit('app/TOGGLE_OVERLAY', { text, type, variant })
    },
    // =============================================================================
    // Close global overLay/loading
    // =============================================================================
    closeLoading() {
      this.$store.commit('app/TOGGLE_OVERLAY', null)
    },
    // =============================================================================
    // Global function to handle axios errors
    // =============================================================================
    handleError(error) {
      let title
      let text
      let color
      if (!error.response) {
        [title, text, color] = ['NETWORK ERROR', 'Try Reloading', 'danger']
      } else {
        const code = error.response.status
        const response = error.response.data
        if (code === 400) {
          [title, text, color] = [
            'Invalid Input',
            response.error ? response.error : response,
            'warning',
          ]
        }
        if (code === 401) {
          [title, text, color] = [
            'Permission Denied',
            'Please contact the system admin',
            'danger',
          ]
        }
        if (code === 500) {
          [title, text, color] = [
            'Internal Server Error',
            response.message,
            'danger',
          ]
        }
        if (code === 401) {
          [title, text, color] = [
            'Please login again',
            response.message,
            'danger',
          ]
          localStorage.clear()
          this.$router.push({ name: 'auth-login' })
        }
        if (code === 404) {
          [title, text, color] = ['Not found', response.message, 'danger']
          this.$router.push({ name: 'error-404' })
        }
      }
      this.closeLoading()
      this.$bvToast.toast(text, {
        title,
        variant: color,
        autoHideDelay: 5000,
        solid: true,
      })
    },
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Vue.prototype.$http = axios
