import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcodes',
      name: 'list-barcodes',
      component: () => import('@/views/Barcodes.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'All Barcodes',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
          {
            text: 'All Barcodes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcodes/registered',
      name: 'list-registered-barcodes',
      component: () => import('@/views/RegisteredBarcode.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'Registered Barcodes',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
          {
            text: 'Registered Barcodes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcodes/add',
      name: 'add-barcode',
      component: () => import('@/views/AddBarcode.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'Add Barcode',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
          {
            text: 'Add Barcode',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcodes/pickup',
      name: 'list-barcode-pickup',
      component: () => import('@/views/kitPickup.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'Kit Pickup Requests',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
          {
            text: 'Kit Pickup Requests',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcode/kid/:id/report/upload',
      name: 'upload-kid-report',
      component: () => import('@/views/UploadKidReport.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'Upload Report',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
          {
            text: 'Registered Barcodes',
            active: false,
          },
          {
            text: 'Upload Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcode/adult/:id/report/upload',
      name: 'upload-adult-report',
      component: () => import('@/views/UploadAdultReport.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'Upload Report',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
          {
            text: 'Registered Barcodes',
            active: false,
          },
          {
            text: 'Upload Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcode/longevity/:id/report/upload',
      name: 'upload-longevity-report',
      component: () => import('@/views/UploadLongevityReport.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'Upload Report',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
          {
            text: 'Registered Barcodes',
            active: false,
          },
          {
            text: 'Upload Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcode/covid/:id/report/upload',
      name: 'upload-covid-report',
      component: () => import('@/views/UploadCovidReport.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'Upload Covid Report',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
          {
            text: 'Registered Barcodes',
            active: false,
          },
          {
            text: 'Upload Covid Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcode/kid/:id/report/view',
      name: 'view-kid-report',
      component: () => import('../views/ViewKidReport.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'View Report',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
          {
            text: 'Registered Barcodes',
            active: false,
          },
          {
            text: 'View Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcode/adult/:id/report/view',
      name: 'view-adult-report',
      component: () => import('../views/ViewAdultReport.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'View Report',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
          {
            text: 'Registered Barcodes',
            active: false,
          },
          {
            text: 'View Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcode/longevity/:id/report/view',
      name: 'view-longevity-report',
      component: () => import('../views/ViewLongevityReport.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'View Report',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
          {
            text: 'Registered Barcodes',
            active: false,
          },
          {
            text: 'View Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/barcode/covid/:id/report/view',
      name: 'view-covid-report',
      component: () => import('../views/ViewCovidReport.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'View Covid Report',
        breadcrumb: [
          {
            text: 'Home',
            active: false,
          },
          {
            text: 'Registered Barcodes',
            active: false,
          },
          {
            text: 'View Covid Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/questionnaireResponses',
      name: 'list-questionnaire-responses',
      component: () => import('@/views/QuestionnaireResponses.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'List Questionnaire Responses',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/consumers',
      name: 'list-consumers',
      component: () => import('@/views/Consumers.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'List Consumers',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/kitUsers',
      name: 'list-kit-users',
      component: () => import('@/views/KitUsers.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'List Kit Users',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/TATView',
      name: 'TAT-view',
      component: () => import('@/views/TATView.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'View TAT',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/analyticsData',
      name: 'analytics-data',
      component: () => import('@/views/AnalyticsData.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'Wet Lab Status',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/feedbacks',
      name: 'list-feedbacks',
      component: () => import('@/views/FeedBackList.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'Feedback List',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bug-report',
      name: 'bug-report',
      component: () => import('@/views/BugReport.vue'),
      meta: {
        pageTitle: 'Bug Report',
        breadcrumb: [
          {
            text: 'Home',
          },
          {
            text: 'Bug Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'auth-register',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/comingSoon',
      name: 'comingSoon',
      component: () => import('@/views/error/ComingSoon.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/notAuthorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('adminData')
  if (to.name === 'auth-login') return next()
  if (to.name === 'auth-register') return next()
  if (
    to.name === 'bug-report'
    && typeof process !== 'undefined'
    && typeof process.env !== 'undefined'
    && process.env.VUE_APP_ENV === 'development'
  ) return next()
  if (to.name === 'error-404') return next()
  if (to.name === 'misc-not-authorized') return next()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  // ? This sets redirect according to userRole
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
